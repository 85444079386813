
<template>

    <b-card title="Add Subscription Category">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                    <!-- Name -->
               <b-col cols="12">
  <b-form-group label="Category" label-for="category-select">
    <validation-provider name="Category" rules="required" #default="{ errors }">
      <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
        <b-input-group-prepend is-text>
          <feather-icon icon="TagIcon" />
        </b-input-group-prepend>

        <!-- Dropdown for selecting categories -->
        <b-form-select id="category-select" v-model="selectedCategory" :state="errors.length > 0 ? false : null">
          <option v-for="category in rows" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </b-form-select>
      </b-input-group>
      
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</b-col>

  <b-col cols="12">
    <b-form-group label="Tag" label-for="Tag">
      <div v-for="(find, index) in finds" :key="find.id">
        <b-input-group>
          <!-- Input field for the tag value -->
          <b-form-input v-model="find.value" ></b-form-input>
          
      <b-form-select 
  id="category-select" 
  v-model="find.subselectedCategory" 
  multiple
  :state="errors.length > 0 ? false : null">
  <option v-for="category in subfind" :key="category.id" :value="category.id">
    {{ category.name }}
  </option>
</b-form-select>


          <!-- Delete button for the tag -->
          <b-input-group-append>
            <b-button @click="deleteFind(index)" variant="outline-primary">Delete</b-button>
            <b-input-group-text>
              <b-icon icon="x" />
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </div>

      <b-col>
        <!-- Button to add a new tag -->
        <b-button @click="addFind" variant="success">
          <plus-icon size="1.2x" class="custom-class"></plus-icon> TAG
        </b-button>
      </b-col>
    </b-form-group>
  </b-col>



                

                 



                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit"
                            @click.prevent="validationForm" variant="primary" class="mr-1">
                            Submit
                        </b-button>
                  

                     
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//Vee-Validate 
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    required,
} from '@validations'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        required,
    },
    directives: {
        Ripple,
    },

data() {
  return {
    rows: [], // Holds categories fetched from Firestore
    finds: [], // Holds tags (each with a value and subselectedCategory)
    subfind: [], // Holds subcategories
    errors: [], // Placeholder for error state (if any)
    selectedCategory: '', // Ensure this is initialized properly
    find: {
      value: [],
      subselectedCategory: [],
    }
  };
},
methods: {
  // Adds a new tag
  addFind() {
    this.finds.push({ id: Date.now(), value: '', subselectedCategory: '' });
  },

  // Deletes a tag
  deleteFind(index) {
    this.finds.splice(index, 1);
  },

  readmenuforsub() {
    store.dispatch('app/commitActivateLoader');
    this.subfind = []; // Clear previous subfind data
    db.collection("menuforsub")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.subfind.push({
            id: doc.id,
            name: doc.data().name // Ensure 'name' exists in Firestore
          });
        });
        store.dispatch('app/commitDeactivateLoader');
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        store.dispatch('app/commitDeactivateLoader');
      });
  },

  // Reads categories from Firestore
  readCategories() {
    store.dispatch('app/commitActivateLoader');
    this.rows = []; // Clear previous category data
    db.collection("subcategory")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.rows.push({
            id: doc.id,
            name: doc.data().name // Ensure 'name' exists in Firestore
          });
        });
        store.dispatch('app/commitDeactivateLoader');
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        store.dispatch('app/commitDeactivateLoader');
      });
  },

  validationForm() {
    this.$refs.simpleRules.validate().then(success => {
      if (success) {
        this.submit();
      }
    });
  },

submit() {
  store.dispatch('app/commitActivateLoader');
  const date = new Date().toLocaleString();

  // Prepare data to be saved
  const dataToSave = this.finds.map(find => ({
    sub_id: this.selectedCategory,
    features: find.value,
    menu_id: find.subselectedCategory,
    created: date,
    modified: date
  }));

  // Save each tag to Firestore
  const batch = db.batch();

  dataToSave.forEach(data => {
    const docRef = db.collection("subfeatures").doc(); // Create a new document reference
    batch.set(docRef, data); // Add to batch
  });

  batch.commit()
    .then(() => {
      console.log("All tags added successfully!");
      store.dispatch('app/commitDeactivateLoader');
               this.$router.push({ name: 'subfeatures-list' });
      this.showNotification('Data Added Successfully!', 'AlignJustifyIcon', 'success');
    })
    .catch((error) => {
      console.error("Error adding documents: ", error);
      store.dispatch('app/commitDeactivateLoader');
      this.showNotification('Error adding data!', 'AlertIcon', 'danger');
    });
},


},

  created() {
    this.readCategories(); // Load categories on component initialization
  },
  mounted() {
    this.readmenuforsub(); // Load subcategories after component is mounted
  },

}
  


</script>


